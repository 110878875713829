import React, { useState } from "react";
import styles from "./Modal.module.css";

function OverLay(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const closeOverLayModal = () => {
    props.setOpenModal(false);
  };

  const sumbitFormHandler = (e) => {
    e.preventDefault();
    const formData = {
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
    };

    props.onSubmit(formData);
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPhoneNumber("");
  };

  return (
    <div className={styles.overlay} onClick={closeOverLayModal}>
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <div className={styles.modal_inner}>
          <h1>Register</h1>

          <div className={styles.modal_form_wrapper}>
            <form onSubmit={sumbitFormHandler} className={styles.modal_form}>
              <p>Fill the form below to get registered</p>{" "}
              <input
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
                placeholder="Enter your first name"
              />
              <input
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Enter your last name"
              />
              <input
                required
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="number"
                placeholder="Enter your phone number"
              />
              <input
                required
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                type="email"
                placeholder="Enter email"
              />
              {!props.isLoading && <button>Register</button>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverLay;
