import React, { useEffect, useRef } from "react";
import styles from "../styles/Motorist.module.css";
import NavBar2 from "../components/navbar/NavBar2";
import motoristsImg from "../assets/motorist/Vendor2.png";
import HowItWorks from "../components/sections/HowItWorks";
import pwa from "../assets/logo/pwa.png";
import playstore from "../assets/logo/Android.png";
import appstore from "../assets/logo/Apple.png";
import Footer from "../components/footer/Footer";
import image1 from "../assets/motorist/iPhone 12 Pro 8 (1).png";
import image2 from "../assets/motorist/iPhone 12 Pro 8.png";
import image3 from "../assets/motorist/iPhone 12 Pro 9 (1).png";
import image4 from "../assets/motorist/iPhone 12 Pro 9.png";
import Flickity from "flickity";
import "flickity/css/flickity.css";

const benefits = [
  "Monitor your cars’ health status",
  "Track your vehicle location at all time",
  "Protect your peace of mind with affordable auto insurance.",
  "Get access to vetted and professional auto mechanics",
  "Buy genuine spare parts from vetted wholesale dealers",
  "Enjoy premium paper fine protection for fine-free driving",
  "Get Reminder to renew your vehicle papers and avoid traffic fines.",
];

function Motorist() {
  const flickityRef = useRef(null);

  useEffect(() => {
    // Initialize Flickity when the component mounts
    flickityRef.current = new Flickity(".motorist_carousel", {
      cellAlign: "center",
      contain: false,
      wrapAround: true,
      autoPlay: 2000,
      prevNextButtons: false,
      pageDots: false,
    });

    // Clean up and destroy Flickity when the component unmounts
    return () => flickityRef.current.destroy();
  }, []);

  return (
    <div>
      <div className={styles.body}>
        <div className={styles.body_inner}>
          <NavBar2 />
          <div className={styles.body_inner_main}>
            <div className={styles.motorist_hero}>
              <h1>Car Owners</h1>
              <div className={styles.motorist_hero_inner}>
                <div className={styles.motorist_hero_image}>
                  <div className={styles.motorist_hero_image_inner}>
                    <img src={motoristsImg} alt="customer" />
                  </div>
                </div>
                <div className={styles.motorist_hero_inner_text}>
                  <p>
                    Monitor your cars' health and location in real-time, get
                    insured and access vetted professional auto mechanics.
                  </p>

                  <div className={styles.motorist_hero_inner_download}>
                    <div className={styles.motorist_hero_inner_download_app}>
                      <div className={styles.download_btn}>
                        <div className={styles.download_btn_image}>
                          <img src={pwa} alt="download" />
                        </div>
                        <div className={styles.download_btn_text}>
                          <a
                            href="https://install.page/motormata"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className={styles.motorist_hero_inner_download_app}>
                      <div className={styles.download_btn}>
                        <div className={styles.download_btn_image}>
                          <img src={playstore} alt="download" />
                        </div>
                        <div className={styles.download_btn_text}>
                          <p>Coming Soon</p>
                          <p>Google Play</p>
                        </div>
                      </div>
                      <div className={styles.download_btn}>
                        <div className={styles.download_btn_image}>
                          <img src={appstore} alt="download" />
                        </div>
                        <div className={styles.download_btn_text}>
                          <p>Coming Soon</p>
                          <p>App Store</p>
                        </div>
                      </div>
                    </div>

                    {/* <div className={styles.request_btn}>
                      <p>Coming soon for mobile download</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Benefits */}
            <div className={styles.motorist_benefits}>
              <div className={styles.motorist_benefits_text}>
                <h1>Benefits</h1>

                <div className={styles.motorist_benefits_wrapper}>
                  {benefits.map((benefit) => (
                    <div className={styles.motorist_benefits_wrapper_inner}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.3479 7.56384L9.7479 18.1638C9.65402 18.2585 9.52622 18.3117 9.3929 18.3117C9.25958 18.3117 9.13178 18.2585 9.0379 18.1638L3.6479 12.7738C3.55324 12.68 3.5 12.5522 3.5 12.4188C3.5 12.2855 3.55324 12.1577 3.6479 12.0638L4.3479 11.3638C4.44178 11.2692 4.56958 11.2159 4.7029 11.2159C4.83622 11.2159 4.96402 11.2692 5.0579 11.3638L9.3879 15.6938L18.9379 6.14384C19.1357 5.95205 19.4501 5.95205 19.6479 6.14384L20.3479 6.85384C20.4426 6.94772 20.4958 7.07552 20.4958 7.20884C20.4958 7.34216 20.4426 7.46995 20.3479 7.56384Z"
                          fill="#14131A"
                        />
                      </svg>
                      <div className={styles.motorist_benefits_text_inner}>
                        <p>{benefit}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.motorist_benefits_image}>
                <div className="motorist_carousel">
                  <div className={styles.motorist_benefits_image_inner}>
                    <img src={image1} alt="phone" />
                  </div>
                  <div className={styles.motorist_benefits_image_inner}>
                    <img src={image2} alt="phone" />
                  </div>
                  <div className={styles.motorist_benefits_image_inner}>
                    <img src={image3} alt="phone" />
                  </div>
                  <div className={styles.motorist_benefits_image_inner}>
                    <img src={image4} alt="phone" />
                  </div>
                </div>
              </div>
            </div>
            <HowItWorks />
            {/* Footer metrics */}
            <div className={styles.hero_metrics}>
              <div className={styles.home_metrics_list}>
                <h1>1,457</h1>
                <p>Services Rendered</p>
              </div>

              <div className={styles.home_metrics_list}>
                <h1>38,041</h1>
                <p>Orders Fulfilled</p>
              </div>

              <div className={styles.home_metrics_list}>
                <h1>85</h1>
                <p>Workshops</p>
              </div>

              <div className={styles.home_metrics_list}>
                <h1>124</h1>
                <p>Merchants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Motorist;
