import React from "react";
import styles from "../styles/About.module.css";
import NavBar2 from "../components/navbar/NavBar2";
import Footer from "../components/footer/Footer";
import storyImg from "../assets/about-images/Vendor2.png";
import missionImg from "../assets/about-images/Customer.png";
import visionImg from "../assets/about-images/Vendor2 (1).png";
import NewsLetter from "../components/sections/NewsLetter";

function About() {
  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className={styles.body}>
        <div className={styles.body_inner}>
          <NavBar2 />
          <div className={styles.body_inner_main}>
            <div className={styles.about_header}>
              <div className={styles.about_header_inner}>
                <h1>About Us</h1>
                <p>
                  Motormata is developing a vertical operating system that
                  empowers motorists to drive with confidence. This system
                  connects them seamlessly with insurers, workshops, merchants,
                  and dealers, offering a comprehensive solution for all their
                  automotive needs.
                </p>
                <div
                  onClick={() => scrollToSection("#body")}
                  className={styles.btn}
                >
                  <p>Explore</p>
                </div>
              </div>
            </div>

            <div className={styles.about_body} id="body">
              <div className={styles.about_body_inner}>
                <div className={styles.about_body_inner_image_wrapper}>
                  <div className={styles.about_body_inner_image_wrapper_inner}>
                    <img src={storyImg} alt="about_image" />
                  </div>
                </div>

                <div className={styles.about_body_inner_text}>
                  <h1>Our Story</h1>
                  <p>
                    Motormata initially started as an online auto insurance
                    agency focusing on 3rd party insurance and vehicle
                    documents. However, the company has evolved to leverage IoT
                    devices, delivering value to various stakeholders such as
                    vehicle owners, insurers, workshops, and spare part
                    merchants, and automobile dealers.
                  </p>
                </div>
              </div>

              <div className={styles.about_body_inner}>
                <div className={styles.about_body_inner_image_wrapper}>
                  <div className={styles.about_body_inner_image_wrapper_inner}>
                    <img src={missionImg} alt="about_image" />
                  </div>
                </div>

                <div className={styles.about_body_inner_text}>
                  <h1>Our Mission</h1>
                  <p>
                    Motormata is dedicated to revolutionizing the automotive
                    sector by providing seamless and integrated solutions that
                    not only enhance the driving experience but also impact
                    stakeholders' profitability. Our focus on safety on the road
                    is paramount, ensuring that our solutions contribute
                    positively to the overall automotive ecosystem.
                  </p>
                </div>
              </div>

              <div className={styles.about_body_inner}>
                <div className={styles.about_body_inner_image_wrapper}>
                  <div className={styles.about_body_inner_image_wrapper_inner}>
                    <img src={visionImg} alt="about_image" />
                  </div>
                </div>

                <div className={styles.about_body_inner_text}>
                  <h1>Our Vision</h1>
                  <p>
                    Our vision is to create a future where driving is safer,
                    more efficient, and enjoyable for everyone. We strive to be
                    a leading innovator in the automotive industry, offering
                    cutting-edge solutions that leverage technology to enhance
                    sustainability, innovation, and customer satisfaction,
                    making Motormata a trusted partner in the automotive
                    ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <NewsLetter />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
