import axios from "axios";

const BASE_URL = `https://bpms.motormata.com/api/v1`;

const Mail_UserID = `Z5ZG0Ua9CXbWizkTO`;

const Service_ID = `service_04mgbhw`;

const template_id = `template_fb93vwr`;

const premiumPapers = {
  registerUser: (payload) => {
    const res = axios({
      url: `${BASE_URL}/premiumpapers/app/register`,
      method: "POST",
      data: JSON.stringify({
        name: payload.name,
        plate_no: payload.plate_no,
        phone_no: payload.phone_no,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  sendTicket: (payload) => {
    const response = axios({
      url: `https://api.emailjs.com/api/v1.0/email/send`,
      method: "POST",
      data: JSON.stringify({
        service_id: Service_ID,
        template_id: template_id,
        user_id: Mail_UserID,
        template_params: {
          to_name: payload.to_name,
          from_name: payload.from_name,
          message: payload.message,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};

export default premiumPapers;
