import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import CardInput from "../inputs/CardInput";
import premiumPapers from "../../utils/api/premiumPapersApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  name: "",
  plate_no: "",
  phone_no: "",
};

function SignUpCard(props) {
  const [values, setValues] = useState(initialValues);
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    if (
      values.name.trim().length >= 1 &&
      values.plate_no.trim().length >= 2 &&
      values.phone_no.trim().length >= 11
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [values.name, values.phone_no, values.plate_no]);

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    console.log({
      name: values.name,
      plate_no: values.plate_no.replace(/[-,:./\\]/g, "").toLowerCase(),
      phone_no: values.phone_no,
    });
    try {
      setIsLoading(true);
      const res = await premiumPapers.registerUser({
        name: values.name,
        plate_no: values.plate_no.replace(/[-,:./\\]/g, "").toLowerCase(),
        phone_no: values.phone_no,
        email: "",
      });
      props.setRegistered(true);
      setIsLoading(false);
      console.log(res.data);
      setValues(initialValues);
    } catch (err) {
      setIsLoading(false);
      console.log(err.response.data);
      toast.error(err.response.data, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div className={styles.card}>
      <ToastContainer />
      <div className={styles.card_header}>
        <h1>
          Never worry about traffic fines from expired vehicle papers again.
        </h1>
        <p>Please enter your valid details below including your plate number</p>
      </div>
      <div className={styles.form}>
        <form onSubmit={formSubmitHandler}>
          <div className={styles.inputs}>
            <CardInput
              placeholder="Enter Name"
              onChange={handleInputChange}
              value={values.name}
              type="name"
              name="name"
            />
            <CardInput
              placeholder="Enter Whatsapp number"
              onChange={handleInputChange}
              value={values.phone_no}
              type="tel"
              name="phone_no"
            />
          </div>
          <div className={styles.inputs}>
            <CardInput
              placeholder="Enter Plate Number"
              onChange={handleInputChange}
              value={values.plate_no}
              type="tel"
              name="plate_no"
            />
            {!isLoading && (
              <button disabled={!formIsValid}>Sign up to avoid fines</button>
            )}
            {isLoading && <button disabled={formIsValid}>Loading...</button>}
            {/* <CardInput placeholder="Plate number" /> */}
          </div>

          <div className={styles.inputs}>
            {/* <CardInput placeholder="Email" /> */}
            {/* <button>Sign up to avoid fines</button> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUpCard;
