import React, { useEffect, useState } from "react";
import styles from "../../styles/PrivacyPolicy.module.css";
import Footer from "../footer/Footer";
import Input from "../inputs/Input";
import premiumPapers from "../../utils/api/premiumPapersApi";
import NavBar2 from "../navbar/NavBar2";

function DeleteAccountRequest() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    if (email.includes("@") && email.includes(".com")) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [email]);

  const submitDeleteAccount = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const deleteAccountRes = await premiumPapers.sendTicket({
        to_name: "Admin",
        from_name: email,
        message: `I want to delete my motormata account. This is my email - ${email}`,
      });
      setLoading(false);
      return deleteAccountRes;
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <div className={styles.body}>
        <div className={styles.body_inner}>
          <NavBar2 title="Premium Papers" route="premium-papers" />
        </div>
      </div>
      <div className={styles.privacy_policy}>
        <div className={styles.body_title}>
          <h1>Delete Account Request</h1>
        </div>
        <div>
          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="mail"
            placeholder="Enter email address"
          />
        </div>
        <div className={styles.button}>
          {!loading ? (
            <button disabled={!formIsValid} onClick={submitDeleteAccount}>
              Request
            </button>
          ) : (
            <button>Sending...</button>
          )}
        </div>

        <div className={styles.privacy_policy_body}></div>
      </div>
      <Footer />
    </div>
  );
}

export default DeleteAccountRequest;
