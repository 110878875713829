import React from "react";
import styles from "../../styles/HowItWorks.module.css";
import Icon1 from "../../assets/svg/icon9.svg";
import Icon2 from "../../assets/svg/icon10.svg";
import Icon3 from "../../assets/svg/icon11.svg";
import dongle1 from "../../assets/dongle/Image.png";
import dongle2 from "../../assets/dongle/dongle.png";

const benefits = [
  {
    name: "Connect",
    svg: Icon1,
    about:
      "Plugin the hardware into the OBD2 port of your car, right under your steering.",
  },
  {
    name: "Simple Set Up",
    svg: Icon2,
    about: "Download our FREE mobile app to transform your driving experience.",
  },
  { name: "Drive", svg: Icon3, about: "Start driving with confidence." },
];

function HowItWorks() {
  return (
    <div className={styles.body}>
      <div className={styles.body_inner}>
        <div className={styles.body_title}>
          <h1>How It works</h1>
        </div>
        <div className={styles.cards_outer}>
          {benefits.map((data, index) => {
            return (
              <div key={index} className={styles.card}>
                <div className={styles.icon_wrapper}>
                  <img src={data.svg} alt={data.name} />
                </div>
                <div>
                  <h5>{data.name}</h5>
                </div>
                <div>
                  <p>{data.about}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.dongles}>
          <div className={styles.dongles_text}>
            <h1>Vehicle Tracker and Diagnosis</h1>

            <p>Track your car's location and health in real-time.</p>

            <div className={styles.dongles_text_btn}>
              <div className={styles.dongles_btn}>
                <a
                  href="https://flutterwave.com/store/motormata/xwipkivw0vm66n"
                  target="_blank"
                  rel="noreferrer"
                >
                  Order
                </a>
              </div>
              <div className={styles.dongles_btn}>
                <p>Requset for demo</p>
              </div>
            </div>
          </div>

          <div className={styles.dongle_image}>
            <div className={styles.dongle_image_inner}>
              <img src={dongle1} alt="dongle" />
            </div>

            <div className={styles.dongle_image_text}>
              <p>Dongle Device</p>
              <h1>Vehicle Tracker and Diagnosis</h1>

              <p>TODAY, 10:22 AM</p>
            </div>
          </div>
        </div>

        <div className={styles.dongles}>
          <div className={styles.dongles_text}>
            <h1>Vehicle Tracker and Immobilizer</h1>

            <p>
              Track your car's real-time location and remotely shut it down when
              necessary, using our app.
            </p>

            <div className={styles.dongles_text_btn}>
              <div className={styles.dongles_btn}>
                <a
                  href="https://flutterwave.com/store/motormata/ykhj9qcb7jh59u"
                  target="_blank"
                  rel="noreferrer"
                >
                  Order
                </a>
              </div>
              <div className={styles.dongles_btn}>
                <p>Requset for demo</p>
              </div>
            </div>
          </div>

          <div className={styles.dongle_image}>
            <div className={styles.dongle_image_inner}>
              <img src={dongle2} alt="dongle" />
            </div>

            <div className={styles.dongle_image_text}>
              <p>Tracking Device</p>
              <h1>Vehicle Tracker and Immobilizer</h1>

              <p>TODAY, 10:22 AM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
