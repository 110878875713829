import React, { useEffect, useRef } from "react";
import image1 from "../../assets/diagnosis.png";
import styles from "../../styles/Reviews.module.css";
import Flickity from "flickity";
import "flickity/css/flickity.css";

const reviews = [
  {
    name: "Jide Ogunleye",
    position: "Vehicle Owner",
    review:
      "Thank you guys and service well delivered. I worry less about my cars overheating, thanks to the Smartcar technology.",
  },
  {
    name: "Meek Kay",
    position: "Mechanic",
    review:
      "I’m always grateful for the referrals. Looking forward to the launch of your Smart-Mecho solution for my workshop.",
  },
  {
    name: "Lekan Baruwa",
    position: "Vehicle Owner",
    review:
      "I have always received your services as I need them. And I have no complaints about any of your services but praises.",
  },
  {
    name: "Darrell Steward",
    position: "Vehicle Owner",
    review:
      "My previous mechanic wasted my money with no result. This ended with your diagnosis and referral to a reliable workshop.",
  },
  {
    name: "Obina Anapusim",
    position: "Insurance",
    review:
      "I have been dealing with Motormata for a couple of years now and I have always enjoyed the service delivery. Keep it up!",
  },
];

function Reviews() {
  const flickityRef = useRef(null);

  useEffect(() => {
    // Initialize Flickity when the component mounts
    flickityRef.current = new Flickity(".carousel", {
      cellAlign: "center",
      contain: false,
      wrapAround: true,
      autoPlay: 2000,
      prevNextButtons: false,
      pageDots: false,
    });

    // Clean up and destroy Flickity when the component unmounts
    return () => flickityRef.current.destroy();
  }, []);
  return (
    <div className={styles.body}>
      <div className={styles.body_inner}>
        <div className={styles.body_title}>
          <h1>Customer Reviews</h1>
        </div>
        <div className="carousel">
          {reviews.map((data, index) => {
            return (
              <div key={index}>
                <div className={styles.card}>
                  <div className={styles.image_wrapper}>
                    <div className={styles.image_wrapper_inner}>
                      <img src={image1} alt="Personal display" />
                    </div>
                  </div>
                  <div className={styles.card_header}>
                    <p>{data.name}</p>
                    <p>{data.position}</p>
                  </div>
                  <div className={styles.review}>
                    <p>{data.review}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Reviews;
