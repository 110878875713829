import "./App.css";
import { Route, Routes, useLocation } from "react-router";
import Home from "./pages/Home";
import PremiumPapers from "./pages/premium-papers/PremiumPapers";
import { useEffect } from "react";
import PrivacyPolicy from "./components/sections/PrivacyPolicy";
import DeleteAccountRequest from "./components/sections/DeleteAccountRequest";
import DeleteData from "./components/sections/DeleteData";
import Motorist from "./pages/Motorist";
import Mechanics from "./pages/Mechanics";
import SpareParts from "./pages/SpareParts";
import About from "./pages/About";
import AutoSurePro from "./pages/AutoSurePro";
import ContactUs from "./components/sections/ContactUs";

function App() {
  const location = useLocation();

  // Update the title based on the current location/route
  useEffect(() => {
    const path = location.pathname;
    let title =
      "MotorMata - Your car's location, health and vital information at your fingertips 24/7"; // Set your default title here
    switch (path) {
      case "/":
        title =
          "MotorMata - Your car's location, health and vital information at your fingertips 24/7";
        break;
      case "/premium-papers":
        title =
          "MotorMata Premium Papers - Premium papers is designed to help 5 million vehicle owners in Lagos states to avoid a possible traffic camera fine of between ₦20,000 and ₦60,000 per year per vehicle.";
        break;
      default:
        break;
    }
    document.title = title;
  }, [location]);
  return (
    <div className="App">
      <div className="App_inner">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/motorists" element={<Motorist />} />
          <Route path="/about" element={<About />} />
          <Route path="/mechanics" element={<Mechanics />} />
          <Route path="/spare-parts" element={<SpareParts />} />
          <Route path="/auto-sure-pro" element={<AutoSurePro />} />
          <Route path="/premium-papers" element={<PremiumPapers />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route
            path="/delete-account-request"
            element={<DeleteAccountRequest />}
          />
          <Route path="/delete-data-request" element={<DeleteData />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
