import React, { useState } from "react";
import styles from "../../styles/Motorist.module.css";
import NavBar2 from "../../components/navbar/NavBar2";
import motoristsImg from "../../assets/motorist/Vendor2.png";
import Footer from "../../components/footer/Footer";
import premiumPapers from "../../utils/api/premiumPapersApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const submitContactMessageHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const contactUsRes = await premiumPapers.sendTicket({
        to_name: "Admin",
        from_name: `${firstName} - ${lastName}`,
        message: `
        Email: ${emailAddress},
        Phone number: ${phoneNumber}
        ${message}
        `,
      });
      setIsLoading(false);
      toast.success("Message has been successfully sent.", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setFirstName("");
      setLastName("");
      setEmailAddress("");
      setPhoneNumber("");
      setMessage("");
      return contactUsRes.data;
    } catch (err) {
      setIsLoading(false);
      toast.error(`An error occurred, Try again later.`, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log(err.response.data);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className={styles.body}>
        <div className={styles.body_inner}>
          <NavBar2 />
          <div className={styles.body_inner_main}>
            <div className={styles.motorist_hero}>
              <h1>Contact Us</h1>
              <div className={styles.motorist_hero_inner}>
                <div className={styles.motorist_hero_image}>
                  <div className={styles.motorist_hero_image_inner}>
                    <img src={motoristsImg} alt="customer" />
                  </div>
                </div>
                <form
                  onSubmit={submitContactMessageHandler}
                  className={styles.motorist_hero_inner_text}
                >
                  <input
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    type="text"
                    placeholder="Enter your first name"
                  />

                  <input
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    type="text"
                    placeholder="Enter your last name"
                  />

                  <input
                    required
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    type="text"
                    placeholder="Enter your email"
                  />

                  <input
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="text"
                    placeholder="Enter your phone number"
                  />

                  <textarea
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Enter Message"
                  />

                  {!isLoading && <button>Send Message</button>}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
