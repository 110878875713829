import React from "react";
import styles from "../../styles/PrivacyPolicy.module.css";
import Footer from "../footer/Footer";
import NavBar2 from "../navbar/NavBar2";

function PrivacyPolicy() {
  return (
    <div>
      <div className={styles.body}>
        <div className={styles.body_inner}>
          <NavBar2 />
        </div>
      </div>
      <div className={styles.privacy_policy}>
        <h1>Privacy Policy</h1>

        <div className={styles.privacy_policy_body}>
          <div className={styles.privacy_policy_body_inner}>
            <h3>Privacy Policy</h3>
            <p>
              This Privacy Policy describes how Motormata collects, uses, and
              shares personal information when you visit and use
              www.motormata.com (the "Site").
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Information Collection and Use</h3>
            <p>
              Personal Information <br /> We may collect personal information
              that you provide directly, such as your name, email address, and
              other contact details when you:
            </p>
            <ul>
              <li>Register for an account</li>
              <li>Subscribe to newsletters</li>
              <li>Make a purchase</li>
              <li>Fill out forms on the Site</li>
            </ul>

            <p>
              Usage Data <br /> We may also collect information about your usage
              of the Site, including IP addresses, browser type, operating
              system, and other usage details.
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Cookies and Tracking Technologies</h3>
            <p>
              We use cookies and similar tracking technologies to analyze
              trends, administer the website, track users' movements around the
              site, and gather demographic information about our user base.
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>How We Use Your Information</h3>
            <p>We may use the collected information for purposes such as:</p>
            <ul>
              <li>Personalizing your experience on the Site</li>
              <li>Providing and improving our services</li>
              <li>Responding to your inquiries or requests</li>
              <li>Sending promotional and marketing communications</li>
            </ul>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Information Sharing</h3>
            <p>
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties. This does not include trusted
              third parties who assist us in operating our website, conducting
              our business, or servicing you, as long as those parties agree to
              keep this information confidential.
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Your Choices</h3>
            <p>
              You can choose not to provide certain information, but it may
              limit your ability to use certain features of the Site.
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Security</h3>
            <p>
              We take reasonable measures to protect the information we collect
              from unauthorized access, disclosure, alteration, or destruction.
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. The most
              recent version will be posted on this page with the effective
              date.
            </p>
          </div>

          <div className={styles.privacy_policy_body_inner}>
            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at [tf@motormata.com].
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
