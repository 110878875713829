import React, { useState } from "react";
import styles from "./PremiumPapers.module.css";
import Footer from "../../components/footer/Footer";
import SignUpCard from "../../components/UI/SignUpCard";
import About from "./About";
import Success from "../../components/UI/Success";
import NavBar2 from "../../components/navbar/NavBar2";

function PremiumPapers() {
  const [registered, setRegistered] = useState(false);

  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <div className={styles.papers_wrapper}>
        <div className={styles.body}>
          <div className={styles.body_inner}>
            <div className={styles.nav_bar}>
              <div className={styles.nav_bar_inner}>
                <NavBar2 className={styles.nav} title="Smart Car" route="" />
              </div>
            </div>
            <div className={styles.header_text}>
              <h3>
                Save up to ₦60,000 yearly when you signup for Premium Papers to
                avoid the Lagos state traffic camera fines.
              </h3>

              <div className={styles.btn}>
                <button onClick={() => scrollToSection("#signup")}>
                  Sign up to avoid fines
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="signup" className={styles.sign_up}>
          <div className={styles.sign_up_inner}>
            <div className={styles.sign_up_inner_text}>
              <h3>
                Are you a Vehicle Owner? Drive Fine-Free in Lagos all year round
                with just <span className={styles.span1}>₦5000</span> ₦0 i.e.{" "}
                <span className={styles.span2}>100% Off</span>.
              </h3>
              <p>
                Premium papers help thousands of vehicle owners in Lagos save up
                to ₦60,000 each in traffic fines every year. 100% Fine
                Protection Guaranteed. Stay fine-free, and drive with
                Peace-of-Mind in Lagos State.
              </p>
            </div>

            <div className={styles.card}>
              {!registered && <SignUpCard setRegistered={setRegistered} />}
              {registered && <Success />}
            </div>
          </div>
        </div>
        <div className={styles.about_comp}>
          <About scrollToSection={scrollToSection} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PremiumPapers;
