import React from "react";
import styles from "../styles/Home.module.css";
import NewsLetter from "../components/sections/NewsLetter";
import Reviews from "../components/sections/Reviews";
import Footer from "../components/footer/Footer";
import NavBar2 from "../components/navbar/NavBar2";
import Hero2 from "../components/sections/Hero2";

function Home() {
  return (
    <div>
      <div className={styles.body}>
        <div className={styles.body_inner}>
          <NavBar2 />
          <Hero2 />
        </div>
      </div>
      <Reviews />
      <NewsLetter />
      <Footer />
    </div>
  );
}

export default Home;
