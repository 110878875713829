import React, { useState } from "react";
import styles from "../styles/Motorist.module.css";
import NavBar2 from "../components/navbar/NavBar2";
import sparePartsImg from "../assets/Vendor3.png";
import Footer from "../components/footer/Footer";
import spareParts from "../assets/spareParts.png";
import OverLay from "../components/UI/OverLay";
import premiumPapers from "../utils/api/premiumPapersApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const benefits = [
  "List your products with ease",
  "Increase visibility and reach",
  "Accept order at your finger tips",
  "Streamlined operations with our order management, inventory tracking, and customer communication",
  "Marketing support for promotional opportunities",
];

function SpareParts() {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openOverlayModal = () => {
    setOpenModal(true);
  };

  const submitFormHandler = async (formData) => {
    try {
      setIsLoading(true);
      const mechanicFormRes = await premiumPapers.sendTicket({
        to_name: "Admin",
        from_name: `Registeration of Spare parts (website) - ${formData.firstName} - ${formData.lastName}`,
        message: `A new registeration for spare parts. First name: ${formData.firstName} , Last name: ${formData.lastName}, Phone number: ${formData.phoneNumber} , Email: ${formData.emailAddress}.`,
      });
      setIsLoading(false);
      toast.success("Data has been sent successfully", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return mechanicFormRes.data;
    } catch (err) {
      setIsLoading(false);
      toast.error(`An error occurred, Try again later.`, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log(err.response.data);
    }
  };
  return (
    <div>
      {openModal && (
        <OverLay
          isLoading={isLoading}
          onSubmit={submitFormHandler}
          setOpenModal={setOpenModal}
        />
      )}

      <div className={styles.body}>
        <ToastContainer />
        <div className={styles.body_inner}>
          <NavBar2 />
          <div className={styles.body_inner_main}>
            <div className={styles.motorist_hero}>
              <h1>Spare parts</h1>
              <div className={styles.motorist_hero_inner}>
                <div className={styles.motorist_hero_image}>
                  <div className={styles.motorist_hero_image_inner}>
                    <img src={spareParts} alt="customer" />
                  </div>
                </div>
                <div className={styles.motorist_hero_inner_text}>
                  <p>
                    Expand your reach and boost sales! List your inventory of
                    vehicle spare parts, connect with a wide customer base, and
                    grow your business exponentially.
                  </p>

                  <div className={styles.motorist_hero_inner_download}>
                    <div
                      className={styles.signUp_btn}
                      onClick={openOverlayModal}
                    >
                      <p>Register</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Benefits */}
            <div className={styles.motorist_benefits}>
              <div className={styles.motorist_benefits_text}>
                <h1>Benefits</h1>

                <div className={styles.motorist_benefits_wrapper}>
                  {benefits.map((benefit) => (
                    <div className={styles.motorist_benefits_wrapper_inner}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.3479 7.56384L9.7479 18.1638C9.65402 18.2585 9.52622 18.3117 9.3929 18.3117C9.25958 18.3117 9.13178 18.2585 9.0379 18.1638L3.6479 12.7738C3.55324 12.68 3.5 12.5522 3.5 12.4188C3.5 12.2855 3.55324 12.1577 3.6479 12.0638L4.3479 11.3638C4.44178 11.2692 4.56958 11.2159 4.7029 11.2159C4.83622 11.2159 4.96402 11.2692 5.0579 11.3638L9.3879 15.6938L18.9379 6.14384C19.1357 5.95205 19.4501 5.95205 19.6479 6.14384L20.3479 6.85384C20.4426 6.94772 20.4958 7.07552 20.4958 7.20884C20.4958 7.34216 20.4426 7.46995 20.3479 7.56384Z"
                          fill="#14131A"
                        />
                      </svg>
                      <div className={styles.motorist_benefits_text_inner}>
                        <p>{benefit}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.motorist_benefits_image2}>
                <div className={styles.motorist_benefits_image_inner}>
                  <img src={sparePartsImg} alt="phone" />
                </div>
              </div>
            </div>
            {/* Footer metrics */}
            <div className={styles.hero_metrics}>
              <div className={styles.home_metrics_list}>
                <h1>1,457</h1>
                <p>Services Rendered</p>
              </div>

              <div className={styles.home_metrics_list}>
                <h1>38,041</h1>
                <p>Orders Fulfilled</p>
              </div>

              <div className={styles.home_metrics_list}>
                <h1>85</h1>
                <p>Workshops</p>
              </div>

              <div className={styles.home_metrics_list}>
                <h1>124</h1>
                <p>Merchants</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SpareParts;
