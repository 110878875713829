import React from "react";
import hero_img from "../../assets/home-hero-image.png";
import motoristImg from "../../assets/home/Customer.png";
import styles from "../../styles/Home2.module.css";
import vendor1Img from "../../assets/home/Vendor2.png";
import vendor2Img from "../../assets/home/Vendor2 (1).png";
import { NavLink } from "react-router-dom";

function Hero2() {
  const scrollToSection = (id) => {
    const section = document.querySelector(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className={styles.hero}>
        <div className={styles.hero_text}>
          <h1>Empowering YOU to drive with confidence.</h1>

          <p>
            Discover the ultimate all-in-one app for a seamless vehicle
            ownership experience. Monitor your vehicle's health, track its
            location, connect with trusted insurers, workshops, and spare parts
            vendors, and access vital information anytime, anywhere—all at your
            fingertips, 24/7.
          </p>

          <div onClick={() => scrollToSection("#body")} className={styles.btn}>
            <p>Explore</p>
          </div>
        </div>
        <div className={styles.hero_image}>
          <div className={styles.hero_image_inner}>
            <img src={hero_img} alt="hero img" />
          </div>
        </div>
      </div>

      <div className={styles.hero_metrics} id="body">
        <div className={styles.home_metrics_list}>
          <h1>1,457</h1>
          <p>Services Rendered</p>
        </div>

        <div className={styles.home_metrics_list}>
          <h1>38,041</h1>
          <p>Orders Fulfilled</p>
        </div>

        <div className={styles.home_metrics_list}>
          <h1>85</h1>
          <p>Workshops</p>
        </div>

        <div className={styles.home_metrics_list}>
          <h1>124</h1>
          <p>Merchants</p>
        </div>
      </div>

      <div className={styles.products_wrapper}>
        <div className={styles.home_motorist}>
          <h1>Car Owners</h1>
          <div className={styles.home_motorist_inner}>
            <div className={styles.home_motorist_inner_text}>
              <p>
                Get the All-In-One mobile app for all your car needs. Track your
                car's location and health in real-time, get affordable auto
                insurance covering claims payments, and access vetted
                professional auto mechanics and genuine spare parts.
              </p>
              <NavLink to="/motorists" className={styles.btn}>
                <p>Get Started</p>
              </NavLink>
            </div>
            <div className={styles.home_motorist_image}>
              <div className={styles.home_motorist_image_inner}>
                <img src={motoristImg} alt="benefit" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.home_motorist}>
          <h1>Mechanics</h1>
          <div className={styles.home_motorist_inner}>
            <div className={styles.home_motorist_inner_text}>
              <p>
                Boost revenue by attracting new customers, optimize workshop
                operations, and streamline spare parts ordering for a seamless
                workshop experience.
              </p>
              <NavLink to="/mechanics" className={styles.btn}>
                <p>Get Started</p>
              </NavLink>
            </div>
            <div className={styles.home_motorist_image}>
              <div className={styles.home_motorist_image_inner}>
                <img src={vendor1Img} alt="benefit" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.home_motorist}>
          <h1>Spare parts Vendors</h1>
          <div className={styles.home_motorist_inner}>
            <div className={styles.home_motorist_inner_text}>
              <p>
                Expand your reach and boost sales! List your inventory of
                vehicle spare parts, connect with a wide customer base, and grow
                your business exponentially.
              </p>

              <NavLink to="/spare-parts" className={styles.btn}>
                <p>Get Started</p>
              </NavLink>
            </div>
            <div className={styles.home_motorist_image}>
              <div className={styles.home_motorist_image_inner}>
                <img src={vendor2Img} alt="benefit" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero2;
