import React from "react";
import styles from "./Input.module.css";

function CardInput(props) {
  return (
    <div className={styles.card_form_inner}>
      <input
        value={props.value}
        onChange={props.onChange}
        className={props.emailInvalid ? `${styles.invalidInput}` : ""}
        onBlur={props.onBlur}
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        id="email"
        required={props.required}
      />
    </div>
  );
}

export default CardInput;
