import React from "react";
import styles from "../../styles/NewsLetter.module.css";
import Input from "../inputs/Input";

function NewsLetter() {
  return (
    <div className={styles.body}>
      <div className={styles.body_inner}>
        <div className={styles.body_title}>
          <h1>Subscribe for Newsletter</h1>
        </div>
        <div>
          <Input placeholder="Enter email address" />
        </div>
        <div className={styles.button}>
          <button>Subscribe</button>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
